<template>
	<div class="commonHeight">
		<div class="chargeParking flex">
			<div class="park" :class="active==index?'active':''" @click="change(index,item)"
					 v-for="(item,index) in chargeParking "
					 :key="index">
				{{ item.parkingName }}
			</div>
		</div>
		<div class="rightNum ">
			<div class="topLeft commonRadio topPosition"></div>
			<div class="topRight commonRadio topPosition"></div>
			<div class="bottomPosition"></div>
			<!--			<div class="bottomRight commonRadio bottomPosition"></div>-->
			<div class="number week">
				<span class="circle">周</span>
				<div class="right">
					<div class="flex"><span>进场</span>
						<h2> {{ count }}</h2></div>
					<div class="flex"><span>出场</span>
						<h2> {{ outCount }}</h2></div>
				</div>
			</div>
			<div class="number inPark">
				<span class="circle month">月</span>
				<div class="right">
					<div class="flex"><span>进场</span>
						<h2>{{ monthCount }}</h2></div>
					<div class="flex"><span>出场</span>
						<h2>{{ monthOutCount }}</h2></div>
				</div>
			</div>
			<div class="number lossCoupon">
				<span class="circle quarter">季</span>
				<div class="right">
					<div class="flex"><span>进场</span>
						<h2>{{ quarterCount }}</h2></div>
					<div class="flex"><span>出场</span>
						<h2>{{ quarterOutCount }}</h2></div>
				</div>
			</div>
			<div class="number outPark">
				<span class="circle year">年</span>
				<div class="right">
					<div class="flex"><span>进场</span>
						<h2>{{ yearCount }}</h2></div>
					<div class="flex"><span>出场</span>
						<h2>{{ yearOutCount }}</h2></div>
				</div>
			</div>
		</div>
		<div class="rightContent">
			<div class="time flex">
				<div class="topLeft commonRadio topPosition"></div>
				<div class="bottomRight commonRadio"></div>
				<el-date-picker
						class="timePick"
						placeholder="起始时间"
						v-model="timePick"
				>
				</el-date-picker>
				<el-time-picker
						placeholder="起始时间"
						v-model="startTimeData"
						format="HH:mm"
				>
				</el-time-picker>
				<span class="separate"></span>
				<el-time-picker
						placeholder="结束时间"
						v-model="endTimeData"
						format="HH:mm"
				>
				</el-time-picker>
				<el-button @click="searchByDate">查询</el-button>
			</div>
			<div style="width: 100%; height: 54%">
				<div class="float" style="width: 635px;">
					<div class=" common maxWidth">
						<div class="topLeft commonRadio topPosition"></div>
						<div class="rightPosition "></div>
						<div class="bottomPosition"></div>
						<h3 class="tit">{{ startTimeData | formatHours2 }}-{{ endTimeData | formatHours2 }}车辆进出情况</h3>
						<div class="leftPark flex">
							<div class="frequency flex">
								<div class=" title">
									<strong>进场</strong>
									<div class="flex"><span>{{ inPark }}</span>车次</div>
								</div>
								<echartscom id="inPark" v-if="entryCarOneHour.chartData.length > 0" :nameData="entryCarOneHour.rows"
														:chartData="entryCarOneHour.chartData"></echartscom>
							</div>
							<div class=" frequency flex">
								<div class="title">
									<strong>出场</strong>
									<div class="flex"><span>{{ outPark }}</span>车次</div>
								</div>
								<echartscom
										id="outPark" v-if="sentryCarOneHour.chartData.length > 0"
										:nameData="sentryCarOneHour.rows"
										:chartData="sentryCarOneHour.chartData"></echartscom>
							</div>
						</div>
					</div>
					<div class="common mt maxWidth">
						<div class="topLeft commonRadio topPosition"></div>
						<div class="rightPosition "></div>
						<div class="bottomPosition"></div>
						<h3 class="tit">早高峰(07:00-09:00)车辆统计</h3>
						<div class="leftPark flex">
							<div class=" frequency flex">
								<div class=" title">
									<strong>进场</strong>
									<div class="flex"><span>{{ inPark2 }}</span>车次</div>
								</div>
								<echartscom
										id="inParkMorning"
										v-if="entryCarMorning.chartData.length > 0"
										:nameData="entryCarMorning.rows"
										:chartData="entryCarMorning.chartData"></echartscom>
							</div>
							<div class=" frequency flex">
								<div class=" title">
									<strong>出场</strong>
									<div class="flex"><span>{{ outPark2 }}</span>车次</div>
								</div>
								<echartscom
										id="inParknight"
										v-if="sentryCarMorning.chartData.length > 0"
										:nameData="sentryCarMorning.rows"
										:chartData="sentryCarMorning.chartData"></echartscom>
							</div>
						</div>
					</div>
				</div>
				<div class="float"  style="width: 352px;">
					<div class="common overnight">
						<div class="rightPosition "></div>
						<div class="bottomPosition"></div>
						<h3 class="tit">过夜车辆数</h3>
						<div class="leftPark flex">
							<div class="title flex"><span>{{ inPark3 }}</span>车次</div>
							<echartscom
									id="overnight" v-if="entryCarNight.chartData.length > 0"
									:nameData="entryCarNight.rows"
									:chartData="entryCarNight.chartData"></echartscom>
						</div>
					</div>
					<div class="common overnight">
						<div class="rightPosition "></div>
						<div class="bottomPosition"></div>
						<h3 class="tit">停车少于半小时车辆</h3>
						<div class="leftPark flex">
							<div class="title flex"><span>{{ inPark4 }}</span>车次</div>
							<echartscom
									id="temporary"
									v-if="msg.chartData.length > 0"
									:nameData="msg.rows"
									:chartData="msg.chartData"></echartscom>
						</div>
					</div>
				</div>
				<div class="float gateDisplay">
					<div class="gate common">
						<div class="rightPosition "></div>
						<div class="bottomPosition"></div>
                        <h3 class="tit">闸口进出场数量</h3>
						<histogram v-if="lineInPark.xValue.length>0" id="earnings" type="bar" Xname="闸口名称" :data="lineInPark"></histogram>
					</div>
				</div>
				<div style="clear:both;"></div>
			</div>


			<div class="echarts common ">
				<div class="topLeft commonRadio topPosition"></div>
				<div class="rightPosition "></div>
				<div class="bottomPosition"></div>
				<h3 class="tit">近一周进场次数（总数量：{{ count }}）</h3>
				<div style="height: 180px">
					<histogram id="parkWeek" type="line" :data="parkRate"></histogram>
				</div>
			</div>
		</div>
		<div style="clear:both;"></div>
	</div>

</template>

<script>
import {
	getCarParkInfoList,
	getCarStatisticsByTime,
	getSentryCarStatistics,
	getOvernightCarInfoByOneDay,
    getCarPassingByOneDay
} from "@/api/api";
import moment from "moment";

moment.locale("zh-cn");
export default {
	name: "adminAccount",
	data() {
		return {
			active: 1,
			chargeParking: [],
			date: "2021-03-13",
			timePick: moment().subtract(1, "days"),
			entryCarOneHour: {
				rows: [],
				chartData: []
			},
			entryCarMorning: {
				rows: [],
				chartData: []
			},
			entryCarNight: {
				rows: [],
				chartData: []
			},
			sentryCarOneHour: {
				rows: [],
				chartData: []
			},
			sentryCarMorning: {
				rows: [],
				chartData: []
			},
			msg: {
				rows: [],
				chartData: []
			},
			parkId: "",
			entryCarList: [],
			inPark: 0,
			inPark2: 0,
			inPark3: 0,
			inPark4: 0,
			outPark: 120,
			outPark2: 120,
			outPark3: 120,
			beginTime: moment(new Date()).subtract(1, "hours").format("HH:mm"),
			endTime: moment(new Date()).format("HH:mm"),

			startTimeData: new Date(new Date().getTime() - 1 * 60 * 60 * 1000),
			endTimeData: new Date(),
			parkRate: {
				Yunit: "",
				xValue: [],
				colors: ["#19D4AE"],
				listscore: [
					{ "name": "停车数量", "yValue": [] }
				]
			},
			count: 0,
			monthCount: 0,
			quarterCount: 0,
			yearCount: 0,

            outCount: 0,
            monthOutCount: 0,
            quarterOutCount: 0,
            yearOutCount: 0,

			lineInPark: {
				Yunit: "",
				xValue: [],
                colors: ["#19D4AE", "#5AB1EF"],
				listscore: [
                    { "name": "进场频次", "yValue": [] },
                    { "name": "出场频次", "yValue": [] }
                ]
			}
		};
	},
	watch: {
		parkId: {
			handler() {
				this.initData();
			}
		}
		/*timePick: {
			handler() {
				this.initData();
			}
		}*/
	},
	created() {

	},
	mounted() {
		/*let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
		this.timer = setInterval(() => {
			_this.timePick = new Date(); // 修改数据date
		}, 1000);*/
		// this.drawLine();
		// get type: ajax ==> [{id:1,name:'临时用户'},{id:5,name:'政府用户'},{id:3,name:'VIP'}]


		// ids: [1,5,3]
		// this.msg.rows = ["临时用户", "个人用户", "企业用户", "政府用户"];


		this.getParkingInfo();
		this.getEntryCarInfo();
		this.getStatisticsMorning();
		this.getStatisticsNight();
		this.getHalfHourCarInfo();
		this.getCarPassingByTimes();
		this.getOutCountByCondition();
        this.getCarPassingInAndOut();
	},
	/*beforeDestroy() {
		if (this.timer) {
			clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
		}
	},*/
	methods: {
		searchByDate() {
			this.initData();
		},
		initData() {
			this.getEntryCarInfo();
			this.getStatisticsMorning();
			this.getStatisticsNight();
			this.getHalfHourCarInfo();
			this.getCarPassingByTimes();
			this.getOutCountByCondition();
			this.getCarPassingInAndOut();
		},

		change(index, item) {
			this.active = index;
			this.parkId = item.id;
		},

		//获取停车场
		getParkingInfo() {
			let params = {
				page: 1,
				size: 9999
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				let obj = {
					id: "",
					parkingName: "全部"
				};
				this.chargeParking.push(obj);
				this.chargeParking.push(...res.data.rows);
			});

		},

		//登录时间前一小时车辆进出情况
		getEntryCarInfo() {
			let param = {
				inTime: moment(this.timePick).format("YYYY-MM-DD"),
				outTime: moment(this.timePick).format("YYYY-MM-DD"),
				beginHour: moment(this.startTimeData).format("HH:mm:ss"),
				endHour: moment(this.endTimeData).format("HH:mm:ss"),
				parkId: this.parkId
			};
			//进场用户类型占比
			getCarStatisticsByTime(JSON.stringify(param)).then(res => {
				let arr = [], arr2 = [], sum = 0;
				res.data.data.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
					sum = sum + i.memberTypeNum;
				});
				this.inPark = sum;
				this.entryCarOneHour.rows = arr;
				this.entryCarOneHour.chartData = arr2;
			});

			getSentryCarStatistics(JSON.stringify(param)).then(res => {
				this.outPark = res.data.data.count;
				let arr = [], arr2 = [];
				res.data.data.list.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
				});
				this.sentryCarOneHour.rows = arr;
				this.sentryCarOneHour.chartData = arr2;
			});
		},

		//早高峰车辆统计
		getStatisticsMorning() {
			let param = {
				inTime: moment(this.timePick).format("YYYY-MM-DD"),
				outTime: moment(this.timePick).format("YYYY-MM-DD"),
				beginHour: "07:00:00",
				endHour: "09:00:00",
				parkId: this.parkId
			};
			//进场用户类型占比
			getCarStatisticsByTime(JSON.stringify(param)).then(res => {
				let arr = [], arr2 = [], sum = 0;
				res.data.data.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
					sum = sum + i.memberTypeNum;
				});
				this.inPark2 = sum;
				this.entryCarMorning.rows = arr;
				this.entryCarMorning.chartData = arr2;

			});

			getSentryCarStatistics(JSON.stringify(param)).then(res => {
				this.outPark2 = res.data.data.count;
				let arr = [], arr2 = [];
				res.data.data.list.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
				});
				this.sentryCarMorning.rows = arr;
				this.sentryCarMorning.chartData = arr2;
			});
		},

		//过夜车辆统计
		getStatisticsNight() {
			let param = {
				oneDay: true,
				inTime: moment(this.timePick).subtract(1, "days").format("YYYY-MM-DD"),
				parkId: this.parkId
			};
			getOvernightCarInfoByOneDay(JSON.stringify(param)).then(res => {
				let arr = [], arr2 = [], sum = 0;
				res.data.data.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
					sum = sum + i.memberTypeNum;
				});
				this.inPark3 = sum;
				this.entryCarNight.rows = arr;
				this.entryCarNight.chartData = arr2;
			});
		},

		//停车少于半小时车辆
		getHalfHourCarInfo() {
			let param = {
				entryTime: moment(this.timePick).format("YYYY-MM-DD"),
				isHalf: true,
				parkId: this.parkId
			};
			getSentryCarStatistics(JSON.stringify(param)).then(res => {
				this.inPark4 = res.data.data.count;
				let arr = [], arr2 = [];
				res.data.data.list.forEach(i => {
					arr.push(i.memberType);
					arr2.push(i.memberTypeNum);
				});
				this.msg.rows = arr;
				this.msg.chartData = arr2;
			});
		},

		//停车数量
		getCarPassingByTimes() {
			let params = {
				parkId: this.parkId,
                inTime: moment().subtract(6,'days').format("YYYY-MM-DD"),
				outTime: moment().format("YYYY-MM-DD")
			};
			this.$get("/car/presentCarInfo/carParking", params).then(res => {
				this.parkRate.xValue = res.data.data.dateList;
				//本周数量
				this.count = res.data.data.count;
				//本月数量
				this.monthCount = res.data.data.monthCount;
				//本季数量
				this.quarterCount = res.data.data.quarterCount;
				//本年数量
				this.yearCount = res.data.data.yearCount;
				this.parkRate.listscore[0].yValue = res.data.data.resultList;
			});

		},

        //出场次数
        getOutCountByCondition() {
            let params = {
                parkId: this.parkId,
                entryTime: moment().subtract(6,'days').format("YYYY-MM-DD"),
                outTime: moment().format("YYYY-MM-DD")
            };
            this.$get("/car/sentry-income-info/outCount", params).then(res => {
                //本周数量
                this.outCount = res.data.data.count;
                //本月数量
                this.monthOutCount = res.data.data.monthCount;
                //本季数量
                this.quarterOutCount = res.data.data.quarterCount;
                //本年数量
                this.yearOutCount = res.data.data.yearCount;
            });

        },

        //车辆进出场情况
        getCarPassingInAndOut() {
            let params = {
                parkId: this.parkId,
                inTime: moment(this.timePick).format("YYYY-MM-DD"),
                beginHour : moment(this.startTimeData).format("HH:mm:ss"),
                endHour : moment(this.endTimeData).format("HH:mm:ss")
            };
            getCarPassingByOneDay(JSON.stringify(params)).then(res => {
                let arr = [], arr2 = [], arr3 = [];
                if (res.data.data.length > 0) {
                    res.data.data.forEach(i => {
                        arr.push(i.gate);
                        arr2.push(i.entryCount);
                        arr3.push(i.outCount);
                    });
                    this.lineInPark.xValue = arr;
                    this.lineInPark.listscore[0].yValue = arr2;
                    this.lineInPark.listscore[1].yValue = arr3;
                }
            });
        },
	},
	components: {
		"echartscom": () => import("../admin/echartscom"),
		"histogram": () => import("../admin/histogram")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/adminAccount";
</style>
